* {
  box-sizing: border-box;
}
html {
  height: 100%;
}

body {
  margin: 0px auto;
  padding: 0px;
  max-width: 900px;
  text-align: center;
  background: #fddd31;
  display: flex;
  height: 100%;
  flex-direction: column;
}

h1 {
  font-family: "Work Sans", sans-serif;
}

#pitch {
  font-family: "Work Sans", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-size: 14rem;
}
